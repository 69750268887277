import * as webllm from "@mlc-ai/web-llm";
import { useState, useEffect } from 'react';

export default function Recipe() {
  const [recipeText, setRecipeText] = useState("");

  useEffect(() => {
    main(setRecipeText);
  }, []);


  return (
    <div id="recipe">
      <div>
        <h1>
          Pizza Margherita recipe / What GenAI was made for
        </h1>
        {/* <div>{recipeText ? converter.makeHtml(recipeText) : "Loading recipe..."}</div> */}
        {recipeText ? (<div dangerouslySetInnerHTML={{ __html: recipeText }} />) : "Loading recipe..."}
      </div>
    </div>
  );
}




/**
 * From https://github.com/mlc-ai/web-llm/blob/main/examples/streaming/src/streaming.ts
 * We demonstrate chat completion with streaming, where delta is sent while generating response.
 */
async function main(setRecipeText) {
  const initProgressCallback = (report) => {
    console.log(report.text)
    // setRecipeText(() => report.text);
  };
  // We can also try others at some point (see https://github.com/mlc-ai/web-llm/blob/main/src/config.ts#L553)
  const selectedModel = "Phi-3-mini-4k-instruct-q4f16_1-MLC"; // "Llama-3-8B-Instruct-q4f32_1-MLC";
  const engine = await webllm.CreateMLCEngine(
    selectedModel,
    { initProgressCallback: initProgressCallback },
  );

  const startMessages = [
    {
      role: "system",
      content:
        "You are a food blogger named Antonio Lorenzo Moretti, who writes introductions for recipes. You never actually get to the recipe, you just keep on introducing it. You can for example talk about how it's your favorite, how you tried it at an authentic place and how it reminded you of a family member. Feel free to elaborate with long anecdotes. Make sure to never write the actual recipe, instead promise a recipe and keep the readers attention by starting a new anecdote. You can use html in your answer.",
    },
    { role: "user", content: "Please introduce this pizza margherita recipe" },
  ]
  let request = {
    stream: true,
    stream_options: { include_usage: true },
    messages: startMessages,
  };
  let message = "";
  while(true){ // Keep generating forever
    console.log("Using request: " + JSON.stringify(request));
    const asyncChunkGenerator = await engine.chat.completions.create(request);
    let latestResponse = "";
    for await (const chunk of asyncChunkGenerator) {
      const newText = chunk.choices[0]?.delta?.content || "";
      latestResponse += newText;
      message += newText;
      // eslint-disable-next-line no-loop-func
      setRecipeText(() => message);
    }
    // context is start messages + the latest stuff. In-between is thrown away to not overflow the context size
    request.messages = startMessages.concat([{role: "assistant", content: latestResponse}, { role: "user", content: "" }])
  }
}